import { MenuProvider } from "@/contexts/MenuContext"
import "@fontsource/bebas-neue"
// import "@fontsource/inter/variable-full.css"
import "@fontsource/inter/latin-400.css"
import "@fontsource/inter/latin-500.css"
import "@fontsource/inter/latin-600.css"
import "@fontsource/inter/latin-700.css"
import "@fontsource/inter/latin-800.css"
import "@fontsource/fahkwang"
import React from "react"

import "./src/styles/global.css"

// export const onPreRouteUpdate = ({ location, prevLocation }) => {
//   console.log("Gatsby started to change location to", location.pathname)
//   console.log("Gatsby started to change location from", prevLocation ? prevLocation.pathname : null)
// }

export const wrapRootElement = ({ element }) => {
  return <MenuProvider>{element}</MenuProvider>
}

// export const wrapPageElement = ({ element, ...props }) => {
//   return <AnimatePresence mode="wait">{element}</AnimatePresence>
// }
