import React, { useEffect, useState } from "react"

export const MenuContext = React.createContext({
  isOpen: false,
  setIsOpen: () => {},
  shrink: false,
  setShrink: () => {},
  scrolled: false,
  setScrolled: () => {},
  transitionColor: null,
  setTransitionColor: () => {},
  workActive: null,
  setWorkActive: () => {},
})

export const MenuProvider = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [workActive, setWorkActive] = useState(false)
  const [shrink, setShrink] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  const [transitionColor, setTransitionColor] = useState(null)

  useEffect(() => {
    if (!transitionColor) {
      setScrolled(false)
      setShrink(false)
      setIsOpen(false)
    } else {
      setIsOpen(false)
    }
  }, [transitionColor])

  return (
    <MenuContext.Provider
      value={{
        isOpen,
        setIsOpen,
        shrink,
        setShrink,
        scrolled,
        setScrolled,
        transitionColor,
        setTransitionColor,
        workActive,
        setWorkActive,
      }}
    >
      {props.children}
    </MenuContext.Provider>
  )
}

export const useMenuContext = () => {
  const {
    isOpen,
    setIsOpen,
    shrink,
    setShrink,
    scrolled,
    setScrolled,
    transitionColor,
    setTransitionColor,
    workActive,
    setWorkActive,
  } = React.useContext(MenuContext)
  return {
    isOpen,
    setIsOpen,
    shrink,
    setShrink,
    scrolled,
    setScrolled,
    transitionColor,
    setTransitionColor,
    workActive,
    setWorkActive,
  }
}
